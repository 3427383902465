import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.default};
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.orbitalSix};

  & > :last-child {
    flex: 1 1 auto;
  }
`;

export const Description = styled.div`
  margin-bottom: ${({ theme }) => theme.orbitalFour};
`;

export const NavigationContainer = styled.div``;

export const Title = styled.div`
  margin-bottom: ${({ theme }) => theme.orbitalTwo};
`;
