import { useCallback } from 'react';
import { UserPreferencesSvcClient } from '@jarvis/web-stratus-client';
import { useApiCall, useRootContext } from '@jarvis/react-portal-addons';

const useUserPreferencesListCall = () => {
  const { shell, stack } = useRootContext();
  const { authProvider } = shell;

  const apiCall = useCallback(
    async ({ tenantId, userId }) => {
      const client = new UserPreferencesSvcClient(stack, authProvider);

      return client.getUserPreferencesList({
        params: {
          startIndex: 0,
          maxResult: 1,
          userId,
          tenantId,
        },
      });
    },
    [authProvider, stack],
  );

  return useApiCall({
    apiCall,
    init: false,
  });
};

export default useUserPreferencesListCall;
