import { Select } from '@veneer/core';
import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const LanguageSelect = styled(Select)`
  max-width: 352px;
`;

export const DescriptionParagraph = styled.p`
  margin: ${primitives.layout.size6}px 0 ${primitives.layout.size6}px
  ${primitives.layout.size2}px;
`;
