import { useCallback } from 'react';
import { useApiCall, useRootContext } from '@jarvis/react-portal-addons';
import { UserMgtSvcClientv3 } from '@jarvis/web-stratus-client';

const useMeCall = ({ init }) => {
  const { shell, stack } = useRootContext();
  const { authProvider } = shell;

  const apiCall = useCallback(async () => {
    const client = new UserMgtSvcClientv3(stack, authProvider);
    const response = await client.getCurrentActiveUser();

    return response;
  }, [authProvider, stack]);

  return useApiCall({ apiCall, init });
};

export default useMeCall;
