import React from 'react';
import PropTypes from 'prop-types';
import { Container, Description, Icon } from './styles';

const NotificationType = ({
  dataTestids,
  description,
  icon,
  title,
}) => (
  <Container data-testid={dataTestids.dataTestid}>
    <Icon>
      {icon}
    </Icon>

    <div>
      <div className="label" data-testid={dataTestids.title}>
        {title}
      </div>
      <Description className="caption-small" data-testid={dataTestids.description}>
        {description}
      </Description>
    </div>
  </Container>
);

NotificationType.propTypes = {
  dataTestids: PropTypes.shape({
    dataTestid: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default NotificationType;
