import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@veneer/core';
import {
  ContextualToolbar,
  LoaderWidget,
  MFELoader,
  NavigationArea,
  useCallSuccess,
  useI18n,
} from '@jarvis/react-portal-addons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import NotificationsPreferences from '../NotificationsPreferences';
import LanguagePreferences from '../LanguagePreferences';
import {
  Container, Description, NavigationContainer, Title,
} from './styles';
import {
  NotificationsTabControlButtonClicked,
  SettingsLanguageTab,
  publishEvent,
} from '../../utils/analytics';
import useUserPreferencesListCall from '../../hooks/useUserPreferencesListCall';
import useMeCall from '../../hooks/useMeCall';

const TabConfig = {
  notifications: {
    id: 'preferences-notifications-tab',
    hash: 'notif',
  },
  privacy: {
    id: 'preferences-privacy-tab',
    hash: 'priv',
  },
  language: {
    id: 'preferences-language-tab',
    hash: 'lang',
  },
};

const PreferencesPathname = '/settings/preferences';

function getTabByHash(hash, notificationsTabEnabled) {
  switch (hash) {
    case TabConfig.language.hash:
      return TabConfig.language;
    case TabConfig.privacy.hash:
      return TabConfig.privacy;
    default:
      return notificationsTabEnabled
        ? TabConfig.notifications
        : TabConfig.privacy;
  }
}

function getTabById(tabId, notificationsTabEnabled) {
  switch (tabId) {
    case TabConfig.language.id:
      return TabConfig.language;
    case TabConfig.privacy.id:
      return TabConfig.privacy;
    default:
      return notificationsTabEnabled
        ? TabConfig.notifications
        : TabConfig.privacy;
  }
}

function getTabHash(location) {
  const locationHash = location.hash;

  // Removes the # character.
  return locationHash ? locationHash.slice(1) : '';
}

const Preferences = props => {
  const { navigation, tenantHandlerInterface } = props.shell?.v1;
  const { settingsNotificationsTab } = useFlags();
  const tabHash = useMemo(() => getTabHash(navigation.location), [navigation]);

  const { t } = useI18n();

  const [tabHasChange, setTabHasChange] = useState(false);
  const [toolbarCancelClicked, setToolbarCancelClicked] = useState();
  const [toolbarApplyClicked, setToolbarApplyClicked] = useState();
  const [bounceContextualToolbar, setBounceContextualToolbar] = useState(0);
  const [selectedTab, setSelectedTab] = useState(
    getTabByHash(tabHash, settingsNotificationsTab),
  );
  const [showNotificationsTab, setShowNotificationsTab] = useState(false);
  const [userPreference, setUserPreference] = useState(null);
  const [userId, setUserId] = useState(null);

  const meCall = useMeCall(({ init: settingsNotificationsTab }));
  const userPreferencesListCall = useUserPreferencesListCall({ init: false });
  const userPreferencesListMakeApiCall = useCallback(
    args => userPreferencesListCall.makeApiCall(args),
    [userPreferencesListCall],
  );

  const onMeCallSuccess = useCallback(
    data => {
      const { resourceId } = data;
      userPreferencesListMakeApiCall({
        userId: resourceId,
        tenantId: tenantHandlerInterface.getTenantId(),
      });
      setUserId(resourceId);
    },
    [tenantHandlerInterface, userPreferencesListMakeApiCall],
  );

  useCallSuccess({
    call: meCall,
    onSuccess: onMeCallSuccess,
  });

  const onUserPreferencesListRetry = useCallback(
    () => userPreferencesListMakeApiCall({
      userId,
      tenantId: tenantHandlerInterface.getTenantId(),
    }),
    [userId, tenantHandlerInterface, userPreferencesListMakeApiCall],
  );

  const onPreferenceListCallSuccess = useCallback((data, { status }) => {
    const { data: payloadData } = data;

    if (status === 204) {
      setShowNotificationsTab(false);
      setSelectedTab(getTabById(TabConfig.privacy.id));
      return;
    }

    if (payloadData?.resourceList?.length === 0) {
      console.error('No User Preferences created.');
      return;
    }

    setShowNotificationsTab(true);
    setUserPreference(data.resourceList[0]);
  }, []);

  useCallSuccess({
    call: userPreferencesListCall,
    onSuccess: onPreferenceListCallSuccess,
  });

  const PreferencesTabs = useMemo(
    () => ({
      Notifications: {
        id: TabConfig.notifications.id,
        label: t('settings.preferences.notifications'),
      },
      Privacy: {
        id: TabConfig.privacy.id,
        label: t('settings.preferences.privacy'),
      },
      Language: {
        id: TabConfig.language.id,
        label: t('settings.preferences.language'),
      },
    }),
    [t],
  );

  const navigateTo = useCallback(
    pathUrl => {
      if (navigation) {
        navigation.push(pathUrl);
      }
    },
    [navigation],
  );

  const navigateToHash = useCallback(
    hash => {
      navigateTo(`${PreferencesPathname}#${hash}`);
    },
    [navigateTo],
  );

  const breadcrumbsItems = useMemo(
    () => [
      {
        text: t('settings.breadcrumb.home'),
        onClick: e => {
          e.preventDefault();
          navigateTo('/');
        },
        url: '/',
      },
      {
        text: t('settings.breadcrumb.settings'),
        onClick: e => {
          e.preventDefault();
          navigateTo('/settings');
        },
        url: '/settings',
      },
      {
        text: t('settings.breadcrumb.preferences'),
        onClick: e => {
          e.preventDefault();
          navigateTo(`${PreferencesPathname}#${TabConfig.privacy.hash}`);
        },
      },
    ],
    [navigateTo, t],
  );

  useEffect(() => {
    if (userPreferencesListCall.error) {
      setShowNotificationsTab(true);
    }
  }, [userPreferencesListCall.error]);

  // Corner case where the user clicks in an external link,
  // like in the footer, to navigate to a tab while they're still
  // in the Preferences page.
  useEffect(() => {
    function hashListener(location) {
      const hash = getTabHash(location);
      setSelectedTab(getTabByHash(hash, settingsNotificationsTab));
    }

    // 'listen' returns a listener unregister function.
    return navigation.listen(hashListener);
  }, [navigation, selectedTab, setSelectedTab, settingsNotificationsTab]);

  // Inserts the correct hash in the URL after the tab changes.
  useEffect(() => {
    navigateToHash(selectedTab.hash);
  }, [selectedTab, navigateToHash]);

  const content = useMemo(() => {
    if (meCall.pending || userPreferencesListCall.pending) {
      return <LoaderWidget fullScreen />;
    }

    return (
      <Tabs
        data-testid="settings-preferences-tabs"
        controlId="extended"
        keepMounted
        mode="extended"
        onChangeTab={tabId => {
          if (tabHasChange) {
            setBounceContextualToolbar(Math.random());
          } else {
            if (TabConfig.language.id === tabId) {
              publishEvent(SettingsLanguageTab);
            }
            if (TabConfig.notifications.id === tabId) {
              publishEvent(NotificationsTabControlButtonClicked);
            }
            setSelectedTab(getTabById(tabId, settingsNotificationsTab));
            setBounceContextualToolbar(0);
          }
        }}
        selectedTabId={selectedTab.id}
        tabs={[
          ...(showNotificationsTab && settingsNotificationsTab
            ? [
              {
                id: PreferencesTabs.Notifications.id,
                label: PreferencesTabs.Notifications.label,
                content: (
                  <NotificationsPreferences
                    error={meCall.error || userPreferencesListCall.error}
                    onRetry={onUserPreferencesListRetry}
                    userPreference={userPreference}
                  />
                ),
              },
            ]
            : []),
          {
            id: PreferencesTabs.Privacy.id,
            label: PreferencesTabs.Privacy.label,
            content: (
              <MFELoader
                {...props}
                component="@jarvis/portal-consents"
                type="SMBPrivacyManageOptions"
              />
            ),
          },
          {
            id: PreferencesTabs.Language.id,
            label: PreferencesTabs.Language.label,
            content: (
              <LanguagePreferences
                {...props}
                setTabHasChange={setTabHasChange}
                toolbarApplyClicked={toolbarApplyClicked}
                toolbarCancelClicked={toolbarCancelClicked}
                setToolbarCancelClicked={setToolbarCancelClicked}
              />
            ),
          },
        ]}
      />
    );
  }, [
    PreferencesTabs.Language.id,
    PreferencesTabs.Language.label,
    PreferencesTabs.Notifications.id,
    PreferencesTabs.Notifications.label,
    PreferencesTabs.Privacy.id,
    PreferencesTabs.Privacy.label,
    meCall.error,
    meCall.pending,
    onUserPreferencesListRetry,
    props,
    selectedTab.id,
    settingsNotificationsTab,
    showNotificationsTab,
    tabHasChange,
    toolbarApplyClicked,
    toolbarCancelClicked,
    userPreference,
    userPreferencesListCall.error,
    userPreferencesListCall.pending,
  ]);

  return (
    <Container
      className="body"
      data-testid="settings-preferences"
    >
      <NavigationContainer data-testid="settings-preferences-navigation-container">
        <NavigationArea breadcrumbsItems={breadcrumbsItems} />
      </NavigationContainer>
      <Title
        className="title-small"
        data-testid="settings-preferences-title"
      >
        {t('settings.preferences.header')}
      </Title>
      <Description data-testid="settings-description">
        {t('settings.preferences.body')}
      </Description>
      {content}
      <ContextualToolbar
        bounce={bounceContextualToolbar}
        buttons={{
          apply: {
            label: t('settings.preferences.apply'),
            onClick: () => setToolbarApplyClicked(true),
          },
          cancel: {
            label: t('settings.preferences.cancel'),
            onClick: () => setToolbarCancelClicked(true),
          },
        }}
        show={tabHasChange}
      />
    </Container>
  );
};

Preferences.propTypes = {
  shell: PropTypes.shape({
    v1: PropTypes.shape({
      navigation: PropTypes.shape({
        location: PropTypes.shape({
          hash: PropTypes.string,
        }),
        push: PropTypes.func,
      }),
    }),
  }).isRequired,
};

export default Preferences;
