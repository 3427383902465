import { useCallback, useEffect, useState } from 'react';
import { AccountMgtSvcClient, UserMgtSvcClientv3 } from '@jarvis/web-stratus-client';
import { getOfferingOrEntitlement, useApiCall } from '@jarvis/react-portal-addons';
import { GRANTS_TYPES } from '../utils/constants';

const useSettingsApiCall = ({
  accountId,
  authProvider,
  init,
  stack,
}) => {
  const [data, setData] = useState({
    showBillingAndShipping: false,
  });

  const fetchUserInfo = useCallback(async () => {
    const userMgtSvcClient = new UserMgtSvcClientv3(stack, authProvider);
    const response = await userMgtSvcClient.getCurrentActiveUser();
    return response.data;
  }, [authProvider, stack]);

  const fetchProgramInfos = useCallback(async userId => {
    const accountMgtSvcClient = new AccountMgtSvcClient(stack, authProvider);
    const response = await accountMgtSvcClient.getProgramInfos(accountId, userId);
    return response.data;
  }, [accountId, authProvider, stack]);

  const apiCall = useCallback(async () => {
    const userInfo = await fetchUserInfo();
    const userId = userInfo.resourceId;
    return fetchProgramInfos(userId);
  }, [fetchProgramInfos, fetchUserInfo]);

  const response = useApiCall({
    apiCall,
    init,
    initialData: data,
  });

  useEffect(() => {
    if (response?.data) {
      setData({
        showBillingAndShipping: !!getOfferingOrEntitlement(
          response.data,
          GRANTS_TYPES.PRINT_PLAN,
          {
            states: [
              'ENABLED',
              'PENDING',
              'DISABLED',
            ],
          },
        ),
      });
    }
  }, [response?.data]);

  return {
    data,
    error: response.error,
    pending: response.pending,
  };
};

export default useSettingsApiCall;
