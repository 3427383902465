const selectI18n = {
  clear: 'Clear',
  noResults: 'No results found',
  open: 'Open',
  searchPlaceholder: 'Search Items',
  selected: 'Selected',
  showingResult: 'Showing %s result',
  showingResults: 'Showing %s results',
  unselected: 'Unselected',
};

export default selectI18n;
