import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ProgressIndicator } from '@veneer/core';
import { useI18n } from '@jarvis/react-portal-addons';
import {
  ToggleColumnToggleDisabled,
  ToggleColumnToggleEnabled,
  publishEvent,
} from '../../../../utils/analytics';
import { Container, ContainerSpinner, Toggle } from './styles';

const LABEL_IDS = {
  OFF: 'settings.preferences.notificationPreferences.toggle.off',
  ON: 'settings.preferences.notificationPreferences.toggle.on',
};

const ColumnToggle = ({
  onChange, disable, id, value,
}) => {
  const { t } = useI18n();
  const [label, setLabel] = useState(value ? LABEL_IDS.ON : LABEL_IDS.OFF);

  useEffect(() => {
    if (disable) {
      setLabel('');
    } else if (value) {
      setLabel(LABEL_IDS.ON);
    } else {
      setLabel(LABEL_IDS.OFF);
    }
  }, [disable, value]);

  const handleOnChange = useCallback(val => {
    onChange(val);
    if (val) {
      publishEvent(ToggleColumnToggleEnabled);
    } else {
      publishEvent(ToggleColumnToggleDisabled);
    }
  }, [onChange]);

  return (
    <Container data-testid={id}>
      <Toggle
        id={id}
        label={t(label)}
        on={value}
        onChange={val => handleOnChange(val)}
        disabled={disable}
      />
      {disable && (
        <ContainerSpinner>
          <ProgressIndicator />
        </ContainerSpinner>
      )}
    </Container>
  );
};

ColumnToggle.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  disable: PropTypes.bool.isRequired,
};

ColumnToggle.defaultProps = {
  value: false,
};

export default ColumnToggle;
