import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.default};
  column-gap: ${({ theme }) => theme.orbitalSix};
  display: grid;
  grid-template-rows: min-content min-content min-content auto;
  grid-template-columns: repeat(12, 1fr);
  padding: 0 ${({ theme }) => theme.orbitalSix};
  > * {
    grid-column: 1 / -1;
  }
`;

export const Description = styled.div`
  margin-bottom: ${({ theme }) => theme.orbitalFour};
`;

export const NavigationContainer = styled.div``;

export const SettingsContainer = styled.div`
  gap: ${({ theme }) => theme.orbitalSix};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: min-content;
`;

export const Title = styled.div`
  margin-bottom: ${({ theme }) => theme.orbitalFour};
`;

export const LoaderWrapper = styled.div``;
