import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { IconPrinter, useToast } from '@veneer/core';
import { ErrorWidget, useI18n } from '@jarvis/react-portal-addons';
import useUserPreferencesUpdateCall from '../../hooks/useUserPreferencesUpdateCall';
import NotificationType from './Columns/NotificationType';
import ColumnToggle from './Columns/Toggle';
import { Container, Header, Table } from './styles';
import {
  NotificationsModuleDisplayed,
  publishEvent,
} from '../../utils/analytics';

const makeCustomData = isEmailEnabled => btoa(
  JSON.stringify({
    assessRate: isEmailEnabled ? 'oncePerDay' : 'never',
    notificationSettings: [
      {
        category: 'deviceAlert',
        adminAlert: isEmailEnabled,
      },
    ],
  }),
);

const NotificationsPreferences = ({ error, onRetry, userPreference }) => {
  const { t } = useI18n();
  const [deviceAlertEnabled, setDeviceAlertEnabled] = useState(false);
  const { addToast } = useToast();
  const toastIdCounterRef = useRef(0);

  const columns = useMemo(
    () => [
      {
        id: 'notificationType',
        label: 'Notification Type',
      },
      {
        id: 'email',
        label: 'Email',
      },
    ],
    [],
  );

  const userPreferencesUpdateCall = useUserPreferencesUpdateCall();
  const isTogglePending = userPreferencesUpdateCall.pending;

  const onEmailChange = useCallback(
    value => {
      const data = {
        status: 'ENABLED',
        customData: makeCustomData(value),
      };

      userPreferencesUpdateCall.makeApiCall({
        uuid: userPreference.uuid,
        data,
      });
      setDeviceAlertEnabled(value);
    },
    [userPreferencesUpdateCall, userPreference?.uuid],
  );

  useEffect(() => {
    if (!userPreference) {
      return;
    }

    const { customData } = userPreference;
    const decodedData = JSON.parse(atob(customData));
    const isDeviceAlertEnabled = decodedData.notificationSettings[0].adminAlert;

    setDeviceAlertEnabled(isDeviceAlertEnabled);
  }, [userPreference]);

  useEffect(() => {
    publishEvent(NotificationsModuleDisplayed);
  }, []);

  useEffect(() => {
    if (userPreferencesUpdateCall.error) {
      addToast({
        id: toastIdCounterRef.current,
        type: 'negative',
        text: t('settings.preferences.notificationPreferences.toast.error'),
      });
      toastIdCounterRef.current += 1;
      setDeviceAlertEnabled(value => !value);
    } else if (userPreferencesUpdateCall.success) {
      addToast({
        id: toastIdCounterRef.current,
        type: 'positive',
        text: t('settings.preferences.notificationPreferences.toast.success'),
      });
      toastIdCounterRef.current += 1;
    }
  }, [
    addToast,
    userPreferencesUpdateCall.error,
    userPreferencesUpdateCall.success,
    t,
  ]);

  const data = useMemo(
    () => [
      {
        notificationType: (
          <NotificationType
            dataTestids={{
              dataTestid: 'notification-preferences-table-printer',
              description:
                'notifications-preferences-table-printer-description',
              title: 'notifications-preferences-table-printer-title',
            }}
            description={t(
              'settings.preferences.notificationPreferences.printer.description',
            )}
            icon={<IconPrinter size={36} />}
            title={t(
              'settings.preferences.notificationPreferences.printer.title',
            )}
          />
        ),
        email: (
          <ColumnToggle
            onChange={onEmailChange}
            id="notifications-preferences-table-printer-toggle"
            value={deviceAlertEnabled}
            disable={isTogglePending}
          />
        ),
      },
    ],
    [deviceAlertEnabled, isTogglePending, onEmailChange, t],
  );

  const content = useMemo(() => {
    if (error) {
      return (
        <ErrorWidget
          dataTestId="error-widget"
          fullScreen
          labelRetry="Retry"
          onRetry={onRetry}
        />
      );
    }

    return (
      <>
        <Header data-testid="notifications-preferences-header">
          {t('settings.preferences.notificationPreferences.body')}
        </Header>

        <Table
          data-testid="notifications-preferences-table"
          columns={columns}
          data={data}
        />
      </>
    );
  }, [columns, data, error, onRetry, t]);

  return <Container>{content}</Container>;
};

NotificationsPreferences.propTypes = {
  error: PropTypes.bool,
  onRetry: PropTypes.func.isRequired,
  userPreference: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    customData: PropTypes.string.isRequired,
  }),
};

NotificationsPreferences.defaultProps = {
  error: false,
  userPreference: null,
};

export default NotificationsPreferences;
