import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { LoaderWidget, NavigationArea, useI18n } from '@jarvis/react-portal-addons';
import Tile from './Tile';
import AccountProfileImg from '../../assets/images/account-profile.svg';
import BillingImg from '../../assets/images/billing.svg';
import PreferencesImg from '../../assets/images/preferences.svg';
import ShippingImg from '../../assets/images/shipping.svg';
import {
  SettingsPreferencesButton,
  publishEvent,
  settingsAccountProfileButtonCLicked,
} from '../../utils/analytics';
import {
  Container,
  Description,
  LoaderWrapper,
  NavigationContainer,
  SettingsContainer,
  Title,
} from './styles';

const Settings = props => {
  const navigation = path(['shell', 'v1', 'navigation'], props);
  const { settingsApiCall, grantsCall } = props;

  const { t } = useI18n();

  const navigateToHome = useCallback(() => {
    if (navigation) {
      navigation.push('/');
    }
  }, [navigation]);

  const breadcrumbsItems = useMemo(() => (
    [
      {
        text: t('settings.breadcrumb.home'),
        onClick: e => {
          e.preventDefault();
          navigateToHome();
        },
        url: '/',
      },
      {
        text: t('settings.breadcrumb.settings'),
        onClick: e => {
          e.preventDefault();
        },
      },
    ]
  ), [navigateToHome, t]);

  return (
    <Container className="body" data-testid="settings">
      <NavigationContainer data-testid="settings-navigation-container">
        <NavigationArea breadcrumbsItems={breadcrumbsItems} />
      </NavigationContainer>
      <Title className="title-small" data-testid="settings-title">
        {t('settings.header')}
      </Title>
      <Description className="body" data-testid="settings-description">
        {t('settings.body')}
      </Description>
      {(settingsApiCall.pending || grantsCall.pending) && (
        <LoaderWrapper data-testid="settings-loader">
          <LoaderWidget fullScreen />
        </LoaderWrapper>
      )}
      {(!settingsApiCall.pending && !grantsCall.pending) && (
        <SettingsContainer>
          <Tile
            dataTestId="setting-account-profile"
            description={t('settings.accountProfile.body')}
            imageUrl={`${AccountProfileImg}`}
            onClick={() => {
              publishEvent(settingsAccountProfileButtonCLicked);
              navigation.push('/settings/account-profile');
            }}
            title={t('settings.accountProfile.header')}
          />
          <Tile
            dataTestId="setting-preferences"
            description={t('settings.preferences.body')}
            imageUrl={`${PreferencesImg}`}
            onClick={() => {
              publishEvent(SettingsPreferencesButton);
              navigation.push('/settings/preferences');
            }}
            title={t('settings.preferences.header')}
          />
          {(settingsApiCall?.data?.showBillingAndShipping || grantsCall?.data) && (
            <>
              <Tile
                dataTestId="setting-shipping"
                description={t('settings.shipping.body')}
                imageUrl={`${ShippingImg}`}
                onClick={() => navigation.push('/settings/shipping')}
                title={t('settings.shipping.header')}
              />
              <Tile
                dataTestId="setting-billing"
                description={t('settings.billings.body')}
                imageUrl={`${BillingImg}`}
                onClick={() => navigation.push('/settings/billing')}
                title={t('settings.billing.header')}
              />
            </>
          )}
        </SettingsContainer>
      )}
    </Container>
  );
};

Settings.propTypes = {
  grantsCall: PropTypes.objectOf(PropTypes.any),
  settingsApiCall: PropTypes.objectOf(PropTypes.any),
};

Settings.defaultProps = {
  grantsCall: {},
  settingsApiCall: {},
};

export default Settings;
