import React from 'react';
import { propOr } from 'ramda';
import SettingsMFE from '../src';
import pkg from '../package.json';

/**
 * @function Root Main function
 * @param props
 * @returns
 */
const Root = props => {
  const shellProps = window.Shell || {};
  const source = propOr('', 'source', props);

  return (
    <section
      id={pkg.name}
      style={{
        height: '100%',
        ...(source ? {} : { minHeight: 'calc(100vh - 176px)' }),
      }}
    >
      <SettingsMFE shell={shellProps} {...props} />
    </section>
  );
};

export default Root;
