import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { path } from 'ramda';
import PropTypes from 'prop-types';
import { useToast } from '@veneer/core';
import { UnsavedChangesModal, useI18n } from '@jarvis/react-portal-addons';
import { LANGUAGE_CODES } from '../../utils/constants';
import { DescriptionParagraph, LanguageSelect } from './styles';
import selectI18n from '../../utils/selectI18n';
import {
  SettingsLanguageApplyButton,
  SettingsLanguageCancelButton,
  SettingsLanguageSelectDropDown,
  SettingsLanguageSuccessToast,
  publishEvent,
} from '../../utils/analytics';

const LanguagePreferences = props => {
  const { t } = useI18n();

  const { addToast } = useToast();

  const navigation = path(['shell', 'v1', 'navigation'], props);
  const localization = path(['shell', 'v1', 'localization'], props);

  const {
    setTabHasChange,
    setToolbarCancelClicked,
    toolbarApplyClicked,
    toolbarCancelClicked,
  } = props;

  const { locale, setLocale, setLocalePreference } = localization;
  const currentLocale = locale.replace('-', '_');
  const [selectedLocale, setSelectedLocale] = useState([currentLocale.replace('-', '_')]);

  const languageOptions = useMemo(() => LANGUAGE_CODES
    .map(code => ({ value: code, label: t(`settings.preferences.languages.${code}`) }))
    .sort((a, b) => (a.label > b.label ? 1 : -1)), [t]);

  const [redirectLocation, setRedirectLocation] = useState();
  const [userConfirmedLeaving, setUserConfirmedLeaving] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [languageOptionsToDisplay, setLanguageOptionsToDisplay] = useState([]);

  const updateLocale = useCallback(() => {
    const [language, country] = selectedLocale[0].split('_');

    publishEvent(SettingsLanguageSuccessToast);

    addToast({
      id: 'succesful-language-change-toast-notification',
      type: 'positive',
      text: t('settings.preferences.notificationPreferences.toast.success'),
    });
    setLocalePreference(selectedLocale[0].replace('_', '-'));
    setTimeout(() => setLocale({ language, country }), 500);
  }, [addToast, selectedLocale, setLocale, setLocalePreference, t]);

  const cancelLocaleChange = useCallback(() => {
    setSelectedLocale([currentLocale]);
    setTabHasChange(false);
    setToolbarCancelClicked(false);
  }, [currentLocale, setTabHasChange, setToolbarCancelClicked]);

  useEffect(() => {
    if (toolbarApplyClicked) {
      publishEvent(SettingsLanguageApplyButton);
      updateLocale();
    }
  }, [toolbarApplyClicked, updateLocale]);

  useEffect(() => {
    if (toolbarCancelClicked) {
      publishEvent(SettingsLanguageCancelButton);
      cancelLocaleChange();
    }
  }, [cancelLocaleChange, toolbarCancelClicked]);

  useEffect(() => {
    setLanguageOptionsToDisplay(languageOptions);
  }, [languageOptions]);

  const unregisterBlockCallback = navigation.block(e => {
    if (window.location.pathname === e.pathname) {
      return false;
    }
    if (currentLocale !== selectedLocale[0] && !userConfirmedLeaving) {
      setRedirectLocation(e);
      setShowConfirmationModal(true);
      return false;
    }
    return true;
  });

  const onSearch = useCallback(value => {
    setLanguageOptionsToDisplay(languageOptions
      .filter(item => item
        .label.toLocaleLowerCase(locale)
        .includes(value.toLocaleLowerCase(locale))));
  }, [languageOptions, locale, setLanguageOptionsToDisplay]);

  const i18n = useMemo(() => ({
    ...selectI18n,
    noResults: t('settings.preferences.select.noResults'),
    searchPlaceholder: t('settings.preferences.searchLanguagePlaceholder'),
    showingResult: t('settings.preferences.select.showingResult'),
    showingResults: t('settings.preferences.select.showingResults'),
  }), [t]);

  return (
    <>
      <DescriptionParagraph>{t('settings.preferences.languageSelectDescription')}</DescriptionParagraph>
      <LanguageSelect
        i18n={i18n}
        options={languageOptionsToDisplay}
        id="select-language"
        label={`${t('settings.preferences.language')}`}
        visibleOptions={6}
        required
        helperTextVisibility="auto"
        clearIcon={false}
        onChange={selectedOption => {
          setSelectedLocale([selectedOption.value]);
          if (currentLocale !== selectedOption.value) {
            setTabHasChange(true);
            publishEvent(SettingsLanguageSelectDropDown);
          } else {
            setTabHasChange(false);
          }
        }}
        value={selectedLocale}
        showSearch
        onSearch={onSearch}
      />
      <UnsavedChangesModal
        show={showConfirmationModal}
        onLeave={() => {
          setUserConfirmedLeaving(true);
          unregisterBlockCallback();
          navigation.push(redirectLocation);
          setShowConfirmationModal(false);
        }}
        onCancel={() => setShowConfirmationModal(false)}
        onClose={() => setShowConfirmationModal(false)}
        i18n={{
          title: t('settings.preferences.unsavedChangesModal.title'),
          body: t('settings.preferences.unsavedChangesModal.body'),
          cancel: t('settings.preferences.cancel'),
          leave: t('settings.preferences.leave'),
        }}
      />
    </>
  );
};

LanguagePreferences.propTypes = {
  setTabHasChange: PropTypes.func.isRequired,
  toolbarApplyClicked: PropTypes.bool.isRequired,
  toolbarCancelClicked: PropTypes.bool.isRequired,
  setToolbarCancelClicked: PropTypes.func.isRequired,
};

export default LanguagePreferences;
