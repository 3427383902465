import PropTypes from 'prop-types';
import { path } from 'ramda';

const activity = 'Settings-v01';
const screenPath = '/ReactSmbSettings/';
const eventDetailVersion = '1.1.0';
const eventDetailVersionOneDotThree = '1.3.0';

export const publishEvent = event => {
  const publishCdmEvents = path(['Shell', 'v1', 'analytics', 'publishCdmEvents'], window);

  if (!publishCdmEvents) {
    return;
  }

  publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType: 'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
      eventCategory: 'simpleUi',
      eventDetail: event,
      version: '1.4.0',
    },
  ]);
};

export const AnalyticsEventPropType = {
  action: PropTypes.string.isRequired,
  activity: PropTypes.string.isRequired,
  screenPath: PropTypes.string.isRequired,
  screenName: PropTypes.string.isRequired,
  screenMode: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired,
  controlDetail: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

const ACTIONS = {
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_TOGGLE_ENABLED: 'ControlToggleEnabled',
  CONTROL_TOGGLE_DISABLED: 'ControlToggleDisabled',
  MODULE_DISPLAYED: 'ModuleDisplayed',
  SCREEN_DISPLAYED: 'ScreenDisplayed',
};

// Events

export const SettingsScreenDisplayed = {
  action: 'ScreenDisplayed',
  activity,
  screenPath: '/',
  screenName: 'Settings',
  version: eventDetailVersion,
};

export const settingsAccountProfileButtonCLicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'Settings',
  controlName: 'AccountProfileButton',
  version: eventDetailVersion,
};

export const SettingsPreferencesButton = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'Settings',
  controlName: 'PreferencesButton',
  version: eventDetailVersionOneDotThree,
};

export const SettingsLanguageTab = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'Settings',
  controlName: 'LanguageTab',
  version: eventDetailVersionOneDotThree,
};

export const SettingsLanguageSelectDropDown = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'Preferences',
  screenMode: 'Language',
  controlName: 'SelectLanguageDropDown',
  version: eventDetailVersionOneDotThree,
};

export const SettingsLanguageApplyButton = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'Preferences',
  screenMode: 'Language',
  controlName: 'ApplyButton',
  version: eventDetailVersionOneDotThree,
};

export const SettingsLanguageCancelButton = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'Preferences',
  screenMode: 'Language',
  controlName: 'CancelButton',
  version: eventDetailVersionOneDotThree,
};

export const SettingsLanguageSuccessToast = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName: 'SelectLanguageSuccessToast',
  screenMode: 'Language',
  version: eventDetailVersionOneDotThree,
};

export const ToggleColumnToggleEnabled = {
  action: ACTIONS.CONTROL_TOGGLE_ENABLED,
  activity,
  screenPath,
  screenName: 'Preferences',
  controlName: 'DeviceAlertEmailToggle',
  version: eventDetailVersionOneDotThree,
};

export const ToggleColumnToggleDisabled = {
  action: ACTIONS.CONTROL_TOGGLE_DISABLED,
  activity,
  screenPath,
  screenName: 'Preferences',
  controlName: 'DeviceAlertEmailToggle',
  version: eventDetailVersionOneDotThree,
};

export const NotificationsModuleDisplayed = {
  action: ACTIONS.MODULE_DISPLAYED,
  activity,
  screenPath,
  screenName: 'Notifications',
  version: eventDetailVersion,
};

export const NotificationsTabControlButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'Settings',
  controlName: 'NotificationTab',
  version: eventDetailVersionOneDotThree,
};
