import styled from 'styled-components';
import { Table as TableVeneer } from '@veneer/core';
import { mixins } from '@jarvis/react-portal-addons';
import primitives from '@veneer/primitives';

export const Container = styled.div`
  margin: ${primitives.layout.size4}px 0;
  padding: ${primitives.layout.size2}px;
`;

export const Header = styled.div`
  margin-bottom: ${primitives.layout.size4}px;
`;

export const Table = styled(TableVeneer)`
  margin: ${primitives.layout.size4}px 0;
  color: black !important;

  #notificationType,
  tr > td:first-child {
    width: calc(100% - 102px) !important;
    max-width: calc(100% - 102px) !important;
    min-width: calc(100% - 102px) !important;
  }

  #email,
  tr > td:last-child {
    width: 102px !important;
    min-width: 102px !important;
    max-width: 102px !important;
  }

  ${mixins.sm(`
    #notificationType, tr > td:first-child {
      width: calc(100% - 144px) !important;
      max-width: calc(100% - 144px) !important;
      min-width: calc(100% - 144px) !important;
    }

    #email, tr > td:last-child {
      width: 144px !important;
      min-width: 144px !important;
      max-width: 144px !important;
    }
  `)}

  ${mixins.md(`
    #notificationType, tr > td:first-child {
      width: calc(100% - 200px) !important;
      max-width: calc(100% - 200px) !important;
      min-width: calc(100% - 200px) !important;
    }

    #email, tr > td:last-child {
      width: 200px !important;
      min-width: 200px !important;
      max-width: 200px !important;
    }
  `)}
`;
