import React from 'react';
import { ToastProvider as VeneerToastProvider } from '@veneer/core';

const ToastProvider = props => (
  <VeneerToastProvider
    position="bottom"
    domRoot={document.querySelector('body')}
    {...props}
  />
);

export default ToastProvider;
