import React from 'react';
import MfePage from '../MfePage';

const Shipping = props => (
  <MfePage
    breadcrumbsPageLabelId="settings.shipping.header"
    mfeProps={{
      ...props,
      component: '@instantink/comfe-loader',
    }}
  />
);

export default Shipping;
