import styled from 'styled-components';
import { mixins } from '@jarvis/react-portal-addons';
import primitives from '@veneer/primitives';

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: ${primitives.layout.size2}px;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.color.foreground.default};
`;

export const Icon = styled.div`
  ${mixins.xs(`
    display: none;
  `)}
`;
