import styled from 'styled-components';
import { Toggle as VeneerToggle } from '@veneer/core';
import primitives from '@veneer/primitives';

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: ${primitives.layout.size2}px;
`;

export const ContainerSpinner = styled.div`
  display: flex;
  margin-left: ${primitives.layout.size2}px;
`;

export const Toggle = styled(VeneerToggle)`
  && > * {
    word-break: normal;
  }
`;
