import { useCallback } from 'react';
import { UserPreferencesSvcClient } from '@jarvis/web-stratus-client';
import { useApiCall, useRootContext } from '@jarvis/react-portal-addons';

const useUserPreferencesUpdateCall = () => {
  const { shell, stack } = useRootContext();
  const { authProvider } = shell;

  const apiCall = useCallback(
    async ({ data, uuid }) => {
      const client = new UserPreferencesSvcClient(stack, authProvider);

      return client.updateUserPreferences({
        data,
        uuid,
      });
    },
    [authProvider, stack],
  );

  return useApiCall({
    apiCall,
    init: false,
  });
};

export default useUserPreferencesUpdateCall;
