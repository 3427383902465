import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { LoaderWidget } from '@jarvis/react-portal-addons';
import { SettingsScreenDisplayed, publishEvent } from './utils/analytics';
import { getLDClientSideID, getUniqueUserKey } from './utils/ldClient';
import App from './App';

const Settings = props => {
  const [Container, setContainer] = useState(() => ({ children }) => children);
  const [fetchingLD, setFetchingLD] = useState(true);

  const locale = props?.shell?.v1?.localization?.locale;

  useEffect(() => {
    publishEvent(SettingsScreenDisplayed);
  }, []);

  useEffect(() => {
    const fetchFetureFlags = async () => {
      try {
        const LDProvider = await asyncWithLDProvider({
          clientSideID: getLDClientSideID(),
          options: {
            bootstrap: 'localStorage',
            streaming: false,
          },
          reactOptions: {
            useCamelCaseFlagKeys: true,
          },
          user: {
            key: getUniqueUserKey(locale),
          },
        });
        setContainer(() => LDProvider);
      } catch (err) {
        console.error(err);
      } finally {
        setFetchingLD(false);
      }
    };
    fetchFetureFlags();
  }, [locale]);

  if (fetchingLD) {
    return <LoaderWidget fullScreen />;
  }

  return (
    <Container>
      <App {...props} />
    </Container>
  );
};

Settings.defaultProps = {
  shell: {},
};

Settings.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any),
};

export default Settings;
