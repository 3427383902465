import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';
import {
  GRANT_CHECK_TYPE,
  GRANT_STATES,
  RootProvider,
  useGrants,
} from '@jarvis/react-portal-addons';
import { path } from 'ramda';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ThemeProviders from './providers/Themes';
import AccountProfilePage from './pages/AccountProfile';
import BillingPage from './pages/Billing';
import PreferencesPage from './pages/Preferences';
import SettingsPage from './pages/Settings';
import ShippingPage from './pages/Shipping';
import { Content } from './styles';
import useSettingsApiCall from './hooks/useSettingsApiCall';
import resources from './assets/locale/index';
import ToastProvider from './components/ToastProvider';
import { GRANTS_LEVELS, GRANTS_TYPES } from './utils/constants';

const INSTANT_INK_GRANT = [{ grant: GRANTS_TYPES.PRINT_PLAN, level: GRANTS_LEVELS.ACCOUNT }];
const INSTANT_INK_GRANT_STATES = [GRANT_STATES.ENABLED, GRANT_STATES.PENDING];

export const history = createBrowserHistory();

const App = props => {
  const { stack } = props;
  const authProvider = path(['shell', 'v1', 'authProvider'], props);
  const orgSelector = path(['shell', 'v1', 'orgSelector'], props);
  const navigation = path(['shell', 'v1', 'navigation'], props);
  const localization = path(['shell', 'v1', 'localization'], props);
  const disableEntitlements = path(
    ['properties', 'disableEntitlements'],
    props,
  );
  const grants = path(['shell', 'v1', 'grants'], props);
  const { grantsSupport } = useFlags();

  const { grantsCall } = useGrants({
    grantsInterface: grants,
    checkType: GRANT_CHECK_TYPE.ALL,
    states: INSTANT_INK_GRANT_STATES,
    grants: INSTANT_INK_GRANT,
    init: grantsSupport,
  });

  const settingsApiCall = useSettingsApiCall({
    accountId: orgSelector?.getOrgTenantId(),
    authProvider,
    init: !disableEntitlements && !grantsSupport,
    stack,
  });

  useEffect(() => {
    // Preloads external MFEs.
    System.import('@jarvis/portal-consents');
    System.import('@jarvis/react-account-profile');
  }, []);

  return (
    <ThemeProviders>
      <RootProvider
        shell={props.shell.v1}
        stack={stack}
        localization={localization}
        resources={resources}
      >
        <ToastProvider>
          <Content>
            <Router history={navigation || history}>
              <Switch>
                <Route path="/settings/account-profile">
                  <AccountProfilePage {...props} />
                </Route>
                <Route path="/settings/preferences">
                  <PreferencesPage {...props} />
                </Route>
                {(settingsApiCall?.data?.showBillingAndShipping
                  || grantsCall?.data) && (
                  <Route path="/settings/shipping">
                    <ShippingPage {...props} />
                  </Route>
                )}
                {(settingsApiCall?.data?.showBillingAndShipping
                  || grantsCall?.data) && (
                  <Route path="/settings/billing">
                    <BillingPage {...props} />
                  </Route>
                )}
                <Route path="/settings">
                  <SettingsPage
                    {...props}
                    settingsApiCall={settingsApiCall}
                    grantsCall={grantsCall}
                  />
                </Route>
              </Switch>
            </Router>
          </Content>
        </ToastProvider>
      </RootProvider>
    </ThemeProviders>
  );
};

App.propTypes = {
  stack: PropTypes.number,
  shell: PropTypes.shape({
    v1: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

App.defaultProps = {
  stack: 0,
};

export default App;
