import React from 'react';
import MfePage from '../MfePage';

const Billing = props => (
  <MfePage
    breadcrumbsPageLabelId="settings.billing.header"
    mfeProps={{
      ...props,
      component: '@instantink/comfe-loader',
    }}
  />
);

export default Billing;
