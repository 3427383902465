import React from 'react';
import { PropTypes } from 'prop-types';
import { is, isEmpty } from 'ramda';
import MfePage from '../MfePage';

const defaultProperties = {
  customStrings: {
    descriptionPassword: true,
    selectLanguageSmall: true,
  },
  useCountryFieldOrganizationTab: true,
  formMaxWidth: '352px',
  hideLanguageSelector: true,
  showOrganizationForm: true,
  showToastRetryButton: true,
  useBottomSaveBar: true,
  showContextualToolbarWithShadow: true,
};

const AccountProfile = props => {
  const accountProfileMfeProps = props?.properties?.accountProfileMfeProps;

  return (
    <MfePage
      breadcrumbsPageLabelId="settings.accountProfile.header"
      mfeProps={{
        ...props,
        component: '@jarvis/react-account-profile',
        properties: {
          ...(!is(Object, accountProfileMfeProps) || isEmpty(accountProfileMfeProps))
            ? defaultProperties
            : accountProfileMfeProps,
        },
      }}
    />
  );
};

AccountProfile.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any),
};

AccountProfile.defaultProps = {
  properties: {},
};

export default AccountProfile;
