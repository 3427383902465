import styled from 'styled-components';
import { Card as VeneerCard } from '@veneer/core';
import { mixins } from '@jarvis/react-portal-addons';

export const Card = styled(VeneerCard)`
  border: none;
  cursor: pointer;
  grid-column: span 12;
  padding: ${({ theme }) => theme.orbitalFour};
  user-select: none;

  ${mixins.md(`
    grid-column: span 6;
  `)}

  ${mixins.xx(`
    grid-column: span 4;
  `)}
`;

export const ChevronRightWrapper = styled.div`
  align-items: center;
  display: flex;
  padding-left: ${({ theme }) => theme.orbitalThree};
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;

export const Description = styled.div`
  flex: 1 1 auto;
  padding-left: ${({ theme }) => theme.orbitalTwo};
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
`;
