import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { MFELoader, NavigationArea, useI18n } from '@jarvis/react-portal-addons';

const MfePage = ({ breadcrumbsPageLabelId, mfeProps }) => {
  const navigation = path(['shell', 'v1', 'navigation'], mfeProps);

  const { t } = useI18n();

  const navigateTo = useCallback(to => {
    if (navigation) {
      navigation.push(to);
    }
  }, [navigation]);

  const breadcrumbsItems = useMemo(() => (
    [
      {
        text: t('settings.breadcrumb.home'),
        onClick: e => {
          e.preventDefault();
          navigateTo('/');
        },
        url: '/',
      },
      {
        text: t('settings.breadcrumb.settings'),
        onClick: e => {
          e.preventDefault();
          navigateTo('/settings');
        },
        url: '/settings',
      },
      {
        text: t(breadcrumbsPageLabelId),
        onClick: e => {
          e.preventDefault();
        },
      },
    ]
  ), [breadcrumbsPageLabelId, navigateTo, t]);

  return (
    <>
      <NavigationArea addPadding breadcrumbsItems={breadcrumbsItems} />
      <MFELoader
        {...(navigation ? { onErrorBackClick: () => navigation.push('/settings') } : {})}
        {...mfeProps}
      />
    </>
  );
};

MfePage.propTypes = {
  breadcrumbsPageLabelId: PropTypes.string.isRequired,
  mfeProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MfePage;
